.header {
  padding-bottom: 10px;
  border-bottom: 1px solid #e5e5e5;
  min-height: 16px;
}

.body {
  padding-top: 15px;
  padding-bottom: 15px;
}

.footer {
  padding-top: 15px;
  text-align: right;
  border-top: 1px solid #e5e5e5;
}
