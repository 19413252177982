.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 99999;
  border-radius: 10px;
  background-color: white;
  padding: 20px;
  min-width: 400px;
  min-height: 200px;
  max-width: calc(100vw - 30px);
  max-height: calc(100vh - 30px);
  overflow-y: auto;
}

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 99999;
  background-color: black;
  opacity: 0.7;
}

.modalClose {
  position: absolute;
  top: 0;
  right: 0;
  padding-right: 12px;
  font-size: 30px;
  color: black;
  text-decoration: none;
  border: none;
  background: none;
}

@media (max-width: 768px) {
  .modal {
    left: 15px;
    top: 15px;
    right: 15px;
    transform: translate(0, 0);
    min-width: auto;
  }
}
