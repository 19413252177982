.visible {
  position: fixed;
  right: 0;
  top: 0;
  width: 220px;
  height: 100vh;
  z-index: 9999;
  background-color: #2e2f36;
  color: lightgray;
  padding-top: 20px;
  padding-right: 25px;
  transition: width 0.3s ease-in-out;

  @media (min-width: 768px) {
    display: none;
  }
}

.hidden {
  width: 0;
  height: 0;
  overflow: hidden;
}

.header {
  padding: 50px 10px 10px 30px;
  color: white;
}

.links {
  padding: 0px 10px 0px 30px;
  line-height: 2;
  text-wrap: nowrap;

  a:hover {
    color: rgb(0, 174, 255);
  }
}
